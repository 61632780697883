@import "../../../variables";

.footer {
  text-align: left;
  padding: 10px 0 10px 25px;

  h5 {
    color: gray;
    margin: 0;
    font-family: "FjallaOne", sans-serif;
  }
}