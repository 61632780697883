@import "../../../../variables";

.payment-heading {
  display: flex;
}

.currency-heading {
  display: flex;

  @media screen and (min-width: $phone-breakpoint) {
    width: 650px;
  }
}

.back-button {
  margin: 23px 0 0 10px;

  :hover {
    color: $bd-accent;
  }
}
