@import "../../../variables";

.AnonPayContainer {
    border: solid 1px grey;
    font-family: Inter, sans-serif;
    padding: 20px;
    border-radius: 12px;
    background-color: $bd-background;
    height: 500px;
    color: whitesmoke;
}