@import "../../../variables";

.select-container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;

  .required::after {
    content: ' *';
    color: red;
  }

  .filled{
    transform: translate(0, 6px) scale(0.8);
  }
}

.select-container label {
  position: absolute;
  pointer-events: none;
  transform: translate(6px, 20px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #6f81a5;
  font-size: 16px;
  line-height: 1;
  left: 10px;
}

.select-container select {
  height: 50px;
  width: 320px;
  font-size: 16px;
  border: none;
  padding: 20px 0 0 20px;
  border-bottom: 1px solid lightgray;
  background-color: rgba(255, 255, 255, 0);
  //padding: 2px 0 0 12px;
  color: #6f81a5;

  &:hover {
    border-bottom: 1px solid #6f81a5;
  }

  .required::after {
    content: ' *';
    color: red;
  }

  @media screen and (min-width: $phone-breakpoint) {
    height: 100%;
  }

}

.select-container:focus-within label {
  transform: translate(0, 6px) scale(0.8);
  color: #0a53e4;
}

.select-container:focus-within input {
  outline: none;
  border-bottom: 1.5px solid #0a53e4;
}

