@import 'variables';

.App {
  text-align: center;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  @media screen and (min-width: $phone-breakpoint) and (min-height: $phone-breakpoint-height) {
    width: 800px;
    left: 50%;
    top: 50%;
    margin-top: -300px;
    margin-left: -400px;
    max-height: 550px;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@font-face {
  font-family: "FjallaOne";
  src: local("FjallaOne-Regular.ttf"), url("./fonts/FjallaOne-Regular.ttf") format("truetype");
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}