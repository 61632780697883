@import '../../../../variables';

.CustomerDetailsSubcontainer {
  height: 100%;
  overflow-y: scroll;

  input[type=submit] {
    margin-top: 15px;
    //margin-bottom: 20px;
    font-size: 16px;
    height: 40px;
    background-color: #6f81a5;
    color: white;
    border-radius: 6px;
    border: none;
    width: 100%;

    &:hover {
      background-color: #acb6ca;
    }

    @media screen and (min-width: $phone-breakpoint) {
      width: 120px;
    }
  }

  .header {

    @media screen and (min-width: $phone-breakpoint) {
      display: flex;
      justify-content: space-between;
    }
  }

  .merchant-logo {
    margin: 15px 20px 0 0;

    img{
      width: 200px;
      height: 46px;
      margin: 6px;
    }
  }

  .form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background: linear-gradient(90deg, rgba(249, 222, 234, 0.15) 0%, rgba(193, 212, 236, 0.15) 100%);
    border-radius: 10px;
    padding: 20px;
    overflow-y: scroll;
    max-height: 300px;
  }
}