@import '../../../variables';

.invoice-details {
  padding: 14px;
  font-size: 14px;
  font-weight: 400;
  overflow-y: scroll;
  text-align: left;
  border: 1px solid $bd-accent-line;
  margin: 0 10px 5px 10px;

  hr {
    width: 92%;
    border-top: whitesmoke;
  }

  .line-items {
    display: flex;
    justify-content: space-between;
    padding: 7px 30px;
    text-align: left;

    h4 {
      padding: 0;
    }
  }

  h4 {
    padding: 7px 30px;
    margin: 15px 0;
  }

  .value {
    min-width: 100px;
    text-align: end;
  }
}

