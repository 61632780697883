@import '../../../variables';

.payment-details {
  width: 97.2%;
  font-size: 14px;
  font-weight: 400;
  height: 420px;
  overflow-y: scroll;
  border-radius: 8px;
  //border: 1px solid $bd-accent;

  @media screen and (min-width: $phone-breakpoint) {
    height: 392px;
    padding: 10px 20px;
  }

  .heading {
    font-size: 16px;
    font-weight: 700;
    padding: 20px 0 6px 12px;
    vertical-align: middle;
    word-wrap: break-word;
    text-align: left;
    font-family: "FjallaOne", sans-serif;
  }

  .values {
    text-align: center;
    font-weight: 400;
    word-wrap: break-word;
    padding: 14px;
    border: 1px solid $bd-accent-line;
    margin: 0 10px 10px 10px;
    border-radius: 2px;

    span.emphasize{
      color: red;
    }
  }

  .qr {
    //max-width: 400px;
  }

  hr {
    background-color: $bd-accent-line;
    height: 1px;
    border: 0;
  }

  .refresh-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .refresh-button {
      width: 94%;
      height: 40px;
      color: white;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-content: center;
      flex-direction: column;
      font-size: 14px;
      background: $bd-accent;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

      @media screen and (min-width: $phone-breakpoint) {
        margin-top: 6px;
        width: 160px;
      }
    }

    :hover {
      background: $bd-accent-2;
    }


    @media screen and (min-width: $phone-breakpoint) {
      margin-top: 6px;
    }
  }
}

.table-fill {
  background: white;
  border-collapse: collapse;
  height: 320px;
  margin: auto;
  max-width: 700px;
  padding: 5px;
  width: 100%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  animation: float 5s infinite;
  table-layout: fixed;
}

tr {
  border-bottom: 1px solid whitesmoke;
  color: #666B85;
  font-weight: normal;
}


td {
  background: #FFFFFF;
  padding: 15px;
  text-align: left;
  vertical-align: middle;
  font-weight: 300;
  font-size: 16px;
}


.expiry_time {
  font-size: 12px;
  color: orangered;
}

.green {
  color: green;
}

.red {
  color: red
}
