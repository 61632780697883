@import "../../../variables";

.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;

  .required::after {
    content: ' *';
    color: red;
  }
}

.input-container input {
  height: 30px;
  width: 300px;
  padding: 20px 0 0 20px;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid lightgray;
  background-color:  rgba(255, 255, 255, 0);

  &:hover {
    border-bottom: 1px solid #6f81a5;
  }

}

.input-container label {
  position: absolute;
  pointer-events: none;
  transform: translate(6px, 20px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #6f81a5;
  font-size: 16px;
  line-height: 1;
  left: 10px;
}

.input-container:focus-within label {
  transform: translate(0, 6px) scale(0.8);
  color: #0a53e4;
}

.input-container:focus-within input {
  outline: none;
  border-bottom: 1.5px solid #0a53e4;
}

.input-container .filled {
  transform: translate(0, 6px) scale(0.8);
}
