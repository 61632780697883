@import '../../../variables';

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  padding: 10px;
  background: $bd-background;
  //background: linear-gradient(90deg, rgba(249, 222, 234, 1) 0%, rgba(193, 212, 236, 1) 100%);
  border-radius: 12px;
  height: 365px;
  overflow-y: scroll;

  @media screen and (min-width: $phone-breakpoint) {
    grid-template-columns: auto auto auto auto;
  }
}

.grid-item {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 20px;
  font-size: 20px;
  font-weight: 200;
  text-align: center;
  border-radius: 8px;
  margin: 8px;
}

.grid-item:hover {
  background-color: rgba(255, 255, 255, 0.4);
}
