@import "../../../variables";

.payment-header {
  text-align: left;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  width: 97.35%;

  @media screen and (min-width: $phone-breakpoint) {
    //margin-left: 20px;
  }

  img {
    margin: 10px 20px 0 0;
    width: 50px;
    height: 50px;

    @media screen and (min-width: $phone-breakpoint) {
      margin: 15px 30px 0 0;
      width: 70px;
      height: 70px;
    }
  }

  h1 {
    font-weight: 700;
    color: whitesmoke;
    font-size: 1.5em;
    margin: 10px 0 0 0;
    font-family: "FjallaOne", sans-serif;

    @media screen and (min-width: $phone-breakpoint) {
      font-size: 2em;
    }
  }

  h3 {
    font-weight: 100;
    color: white;
    margin: 10px 0 10px 0px;
    font-size: 1em;

    span.sub-head{
      color: $bd-accent;
    }


    @media screen and (min-width: $phone-breakpoint) {
      font-size: 1.17em;
    }
  }
}
